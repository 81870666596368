<template>
  <base-layout>
    <div class="section-header">
      <div class="session-head">
        <span class="type" v-if="sessiontype" style="font-size: 0.8rem">{{ sessiontype.name }}</span><br>

      
        <strong class="title"><span v-if="session.code"> {{ session.code }}  - </span> {{ session.title }}</strong>
        
        <!--span ng-if="item.code"> [{{item.code}}]</span--></div>
      <div class="session-head-right" style="font-size: 0.8rem">{{ moment(session.start_time).format('dddd HH:mm') }} -
        {{ moment(session.end_time).format('HH:mm') }} CEST   <span v-if="session.location"> | {{session.location.name}}  </span> <br>
      </div>
    
    </div>
    <ion-content class=" has-header has-subheader has-bottom-banner" id="wcpt-session-detail"
    >
      <div v-if="session">
        <h3 style="padding:10px;color:red" v-if="isRestrictedAccess">Your registration does not include access to this
          content.</h3>

        <div class="session-content" v-if="!isRestrictedAccess">

          <div class="left-side">
            <div class="action-buttons">
              <a
                   class="actn-btn"
                  style="text-decoration: none"
                  v-if="session.wordly_link && isLiveSession && liveSessionActive  || (user && (user.emailBusiness == 'dmoore@world.physio') && session.wordly_link)"
                  href="{{session.wordly_link}}"
                  target="_blank"
              >
                <i class="actn-icon icon ion-chatbox-working"></i> <span class="actn-text" > Translate </span>
              </a>

              <FavoriteButton  :fav-id="favId" v-if="session.id"></FavoriteButton>
              <!--div class="action-btn" ng-click="activeSection = 'evaluation'" style="text-decoration: {{activeSection == 'evaluation' ? 'underline':'none'}}; font-weight: {{activeSection == 'evaluation' ? 'bold':'normal'}}"><i class="icon ion-checkmark-circled"></i> Evaluate</div-->
              <a
                  class="actn-btn" style="text-decoration: none"
                  v-if="session.evaluation_link && session.evaluation_link.indexOf('#') == 0"
                  :href="'#/app/polls/621c8a9127efaaccf48ad38b/sessions/' + session.id"
              >
                <i class="actn-icon icon ion-checkmark-circled"></i> <span class="actn-text" > Evaluate </span>
              </a>
              <a
                   class="actn-btn" style="text-decoration: none"
                  v-if="session.evaluation_link && session.evaluation_link.indexOf('#') != 0"
                  :href="session.evaluation_link" target="_blank"
              >
                <i class="actn-icon icon ion-checkmark-circled"></i> <span class="actn-text" > Evaluate </span>
              </a>
              <!--router-link class="action-btn" style="text-decoration:none"
                           :to="'/app/locationmap/' + session.location.id"
                           v-if="session.location && mapAvailable">
                <i class="icon ion-ios-location-outline"></i>&nbsp;Show on map
              </router-link-->
            </div>
            <div class="stream-container"
                 v-if="((session.liveLink && liveSessionActive) || activeSessions.indexOf(session.id) != -1)">
              <div class="stream">
                <iframe
                    v-if="((isLiveSession && liveSessionActive) || activeSessions.indexOf(session.id) != -1 )"
                    :src="session.liveLink"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                ></iframe>
              </div>
            </div>

            <div
                class="stream-container"
                v-if="session.odLink && liveStreamHasEnded &&  (!liveSessionActive)"
            >
              <div class="stream">
                <iframe
                    v-if="session.odLink"
                    :src="session.odLink"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                ></iframe>
              </div>
            </div>
           
            
            <div class="mobile-activated" style="width:100%"
                 v-if="session.location && session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018'">
              <IonSegment :value="activeTab" class="interactive-segment" style="display:none">
                <IonSegmentButton class="interactive-segment-btn" v-if="!liveStreamHasEnded"
                                  value="interaction"
                                  @click="changeTab('interaction')"
                >
                  <IonLabel>Questions</IonLabel>
                </IonSegmentButton>
                <IonSegmentButton class="interactive-segment-btn" v-if="liveStreamHasEnded"
                                  value="forum"
                                  @click="changeTab('forum')"
                >
                  <IonLabel>Comments</IonLabel>
                </IonSegmentButton>
              </IonSegment>
              <div v-if="activeTab =='interaction' && !liveStreamHasEnded">
                <user-view
                    :session-id="session.id"
                    v-if="loaded"
                    style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                ></user-view>
                <hr>
              </div>
              <div v-if="activeTab =='forum' || liveStreamHasEnded">
                <forum v-if="loaded"
                       class="forum-list"
                       :ref-id="session.id"
                       style="margin:5px 2px;width: 100%; display:inline-block; vertical-align: top;overflow: scroll;overflow-x:hidden;margin-top:10px; border-radius:15px"
                ></forum>
                <hr>
              </div>
            </div>
            <div>
              <div class="session-info">
                <!--LIVE SESSION PRE-LIVE
                <div
                    v-if="isLiveSession && !liveStreamHasStarted"
                >This session is scheduled to start on {{ moment(session.start_time).format('dddd HH:mm') }} CET
                </div>-->
                <!--LIVE SESSION POST-LIVE STREAM-->
                <!--LIVE SESSION POST-LIVE ZOOM-->
              </div>
              <div style="padding-left: 10px">
                <div class="web-person-divider" style="border:none" v-if="persons.length > 0">
                  <h3>Chairs</h3>
                </div>
                <person-list :persons="persons" :picture="false"></person-list>
                <br/>
                <template v-if="sessiontype && sessiontype.name != 'Poster Session'">
                  <div class="web-person-divider" style="border:none" v-if="speakers.length > 0">
                    <h3>Speakers</h3>
                  </div>
                  <person-list :persons="speakers" picture="true"></person-list>
                </template>

              </div>
              <div class="outline" ng-bind-html="session.outline" v-if="session.outline "></div>
              <div class="web-person-divider" style="border:none; text-align: center" v-if="presentations.length > 0">
                <h3>Presentations</h3>
                <hr>
              </div>
              <presentation-list :presentations="presentations"
                                 :show-video="!isLiveSession || (liveStreamHasEnded)"></presentation-list>
            </div>
            <div v-if="activeSection == 'evaluation'">
              <div
                  v-if="sessionHasStarted || (user && user.emailBusiness == 'dmoore@world.physio')"
              >
                
                <!--poll-loader v-if="user"></poll-loader-->
              </div>
              <div v-if="!sessionHasStarted">
                <div style="margin-left:25px">Evaluation is not active for this session</div>
              </div>
            </div>
          </div>

          <div class="right-side mobile-deactivated"
               v-if="session.location && session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018'">

            <IonSegment :value="activeTab" class="interactive-segment">
              <IonSegmentButton class="interactive-segment-btn"
                                value="interaction"
                                @click="changeTab('interaction')" v-if="!liveStreamHasEnded"
              >
                <IonLabel>Questions</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton class="interactive-segment-btn" v-if="liveStreamHasEnded"
                                value="forum"
                                @click="changeTab('forum')"
              >
                <IonLabel>Comments</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            <div v-if="activeTab =='interaction' && !liveStreamHasEnded">
              <user-view
                  :session-id="session.id"
                  v-if="loaded"
                  style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
              ></user-view>
            </div>
            <div v-if="activeTab =='forum' || liveStreamHasEnded ">
              <forum v-if="loaded"
                     class="forum-list"
                     :ref-id="session.id"
                     style="margin:5px 2px;width: 100%; display:inline-block; vertical-align: top;overflow: scroll;overflow-x:hidden;margin-top:10px; border-radius:15px"
              ></forum>
            </div>
          </div>
        </div >
      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {IonContent, IonSegment, IonSegmentButton, IonLabel} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";
import PersonList from "@/components/PersonList";
import PresentationList from "@/components/PresentationList";
import UserView from "@/components/interactive/UserView";

const config = require('@/config');
import Forum from "@/components/interactive/Forum";
import FavoriteButton from "@/components/favorite/FavoriteButton";

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      session: {},
      loaded: false,
      isLiveSession: false,
      persons: [],
      speakers: [],
      presentations: [],
      activeTab: "interaction",
      activeSection: 'details',
      interval: null,
      sessionHasStarted: false,
      sessionHasEnded: false,
      liveStreamHasStarted: false,
      liveStreamHasEnded: false,
      liveSessionActive: false,
      zoomSessionActive: false,
      actualTime: "",
      sessiontype: null,
      favId: null,
      mapAvailable: false,
      isRestrictedAccess: false
    };
  },
  components: {
    IonContent,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    "person-list": PersonList,
    "presentation-list": PresentationList,
    "user-view": UserView,
    forum: Forum,
    FavoriteButton

  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sessions', ['activeSessions'])
  },
  methods: {
    ...mapActions("sessions", [
      "getSingleSession",
      "getPresentationList",
      "getSpeakersInSession",
      "getSessionPersons",
      "getSessionType"
    ]),
    ...mapActions("video", ["stopVideos"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    ...mapActions("maps", ["getMapFromLocation"]),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    changeTab(section) {
      this.activeTab = section;
    },

    getForum(refId) {
      this.$socketInt.emit("getForum", {
        congressId: config.conference,
        refId: refId
      });
    },
    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      let zoomSessionEnd;
      dateNow = new Date(moment.utc());//new Date(this.actualTime);
      sessionStart = new Date(session.start_time + "+01:00");
      sessionEnd = new Date(session.end_time + "+01:00");

      this.sessionHasStarted = dateNow > sessionStart;
      this.sessionHasEnded = dateNow > sessionEnd;
      liveSessionStart = moment(sessionStart).subtract(5, "minutes");
      liveSessionEnd = moment(sessionEnd).add(10, "minutes");
      zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
      this.liveStreamHasStarted = dateNow >= liveSessionStart;
      this.liveStreamHasEnded = dateNow >= liveSessionEnd;
      this.liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      this.zoomSessionActive =
          dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      this.interval = setInterval(() => {
        this.actualTime = moment(this.actualTime).add(5, "seconds");
        dateNow = moment(this.actualTime);
        sessionStart = new Date(session.start_time + "+01:00");
        sessionEnd = new Date(session.end_time + "+01:00");
        this.sessionHasStarted = dateNow > sessionStart;
        this.sessionHasEnded = dateNow > sessionEnd;

        liveSessionStart = moment(sessionStart).subtract(5, "minutes");
        liveSessionEnd = moment(sessionEnd).add(10, "minutes");
        zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
        this.liveStreamHasStarted = dateNow >= liveSessionStart;
        this.liveStreamHasEnded = dateNow >= liveSessionEnd;
        this.liveSessionActive =
            dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
        this.zoomSessionActive =
            dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      }, 5000);
    },

    checkRestriction() {
      if (this.user && this.user.extra && this.user.extra[config.conference]) {
        let extra = this.user.extra[config.conference];
        //let full = extra.tn_category == 'Full';
        let pfd =  extra.tn_category_short == 'pfd';
        let virtsat = extra.tn_category_short == 'virtsat';
        let qm =  extra.tn_category_short == 'qm';
        let pfdSessions = ['da7ad0c0-3ed1-4500-1302-010000000027', 'da7ad0c0-3ed1-4500-1302-010000000028', 'da7ad0c0-3ed1-4500-1302-010000000029', 'da7ad0c0-3ed1-4500-1302-010000000030', 'da7ad0c0-3ed1-4500-1302-010000000031'];
        let qmSessions = ['da7ad0c0-3ed1-4500-1302-010000000152', 'da7ad0c0-3ed1-4500-1302-010000000156', 'da7ad0c0-3ed1-4500-1302-010000000158', 'da7ad0c0-3ed1-4500-1302-010000000159'];

        if (pfd && pfdSessions.indexOf(this.session.id) == -1) {
          this.isRestrictedAccess = true;
        }
        if (qm && qmSessions.indexOf(this.session.id) == -1) {
          this.isRestrictedAccess = true;
        }
        if (virtsat && this.session.start_time.indexOf('2022-03-19T') == -1) {
          this.isRestrictedAccess = true;
        }
      }
    }
  },
  async created() {
    this.moment = moment;
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        if (to.name == 'Session Detail') {
          this.session = await this.getSingleSession(to.params.id);
          this.favId = 'sessions/' + this.session.id;
          console.log('fav from session', this.favId)
          //this.checkRestriction();
          this.isRestrictedAccess = false;
          if (this.session.location) {
            this.mapAvailable = await this.getMapFromLocation(this.session.location.id);
          }
          let trackData = {
            user: this.user,
            item: this.session,
            event: 'enter',
            type: 'session',
            itemId: this.session.id,
          }
          this.trackEvent(trackData)


          this.trackWithLabel(this.session.title)
          this.sessiontype = await this.getSessionType(this.session.id)
          this.getForum(to.params.id);
          let links;
          try {
            let external = this.session.external_link.replace(/'/g, '"');
            links = JSON.parse(external);
          } catch (err) {
            links = this.session.external_link;

          }
          if (links.live && links.live.indexOf('autoplay') != -1) {
            links.live = links.live.replace('autoplay=0', 'autoplay=1')
          }

          this.session.liveLink = links.live;
          this.session.odLink = links.od;
          this.isLiveSession = this.session.location && this.session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018' ? true : false;
          this.actualTime = await this.getServerTime();
          this.checkTime(this.session);

          this.persons = await this.getSessionPersons(this.session.id);
          this.speakers = await this.getSpeakersInSession(this.session.id);
          this.presentations = await this.getPresentationList(this.session.id);
          this.loaded = true;
        } else {
          this.session.liveLink = null;
          this.stopVideos();
          let trackData = {
            user: this.user,
            item: this.session,
            event: 'leave',
            type: 'session',
            itemId: this.session.id,
          }
          this.trackEvent(trackData)
        }
      }
    }
  }
});
</script>
<style lang="scss">
.interactive-segment {
  --background: #fff;
}

.interactive-segment-btn {
  --background: #fff !important;
  --color: 3 d5a59 !important;
  --color-checked: #3d5a59 !important;
  --background-checked: #fff !important;
}
</style>